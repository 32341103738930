<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <h1 style="text-transform: uppercase">{{ pageTitle }}</h1>
        <div class="d-flex align-items-center mb-6">
          <span class="text-muted font-weight-bold font-size-lg flex-grow-1"
            >Erstellen Sie ein hier ein Dokument.</span
          >
          <div class="symbol symbol-50">
            <span class="symbol-label bg-light-light">
              <img
                src="/media/svg/icons/Files/File.svg"
                class="h-50 align-self-center"
                alt=""
              />
            </span>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            :can-cancel="true"
            :is-full-page="true"
          ></loading>
        </div>

        <v-app>
          <v-container>
            <v-row>
              <v-col md="12" class="pt-9 pb-12">
                <div v-if="newInvoice.status !== status_types.STATUS_DRAFT">
                  <v-btn
                    class="primary float-right ma-2 custom_button"
                    type="submit"
                    large
                    @click="sendEmail()"
                  >
                    Sende E-Mail
                    <v-icon dark right>
                      mdi-send-circle
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="primary float-right ma-2 custom_button"
                    type="submit"
                    large
                    width="125"
                    @click="getPdf()"
                  >
                    Download
                    <v-icon dark right>
                      mdi-file-pdf
                    </v-icon>
                  </v-btn>
                                    <v-btn
                                      class="primary float-right ma-2 mr-15 primary"
                                      v-if="this.newInvoice.documents_type_id !== 3"
                                      type="submit"
                                      large
                                      width="160"
                                      @click="editDocument()"
                                    >
                                      Editieren
                                      <v-icon right>
                                        mdi-pencil
                                      </v-icon>
                                    </v-btn>
                </div>
                <div v-if="newInvoice.status === status_types.STATUS_DRAFT">
                  <v-btn
                    class="primary float-right ma-2 ml-10"
                    type="submit"
                    large
                    width="125"
                    @click="saveInvoice(status_types.STATUS_SAVED)"
                  >
                    Bestätigen
                    <v-icon dark right>
                      mdi-file
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="primary float-right ma-2 custom_button"
                    type="submit"
                    large
                    width="125"
                    @click="saveInvoice(status_types.STATUS_DRAFT)"
                  >
                    Speichern
                    <v-icon dark right>
                      mdi-content-save
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="primary float-right ma-2 custom_button"
                    :disabled="!newInvoice.unique_hash || !newInvoice.user_id"
                    type="submit"
                    large
                    width="125"
                    @click="getPDFDraft()"
                  >
                    Vorschau<br />(PDF)
                    <v-icon dark right>
                      mdi-file-pdf
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" class="ma-4">
                <strong>Absender:</strong> {{ company.Name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pb-9">
                <CustomerSelect
                  :selectedCustomer="this.customerSelect"
                  :status="newInvoice.status"
                  @update="customerSelected"
                >
                </CustomerSelect>
              </v-col>
              <v-col cols="6" class="pb-9">
                <v-col cols="6" class="float-right" style="height: 50px;">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="newInvoice.invoice_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    bottom
                    attach
                    :disabled="
                      newInvoice.status === 'SAVED' ||
                        newInvoice.status === 'SEND'
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="newInvoice.invoice_date"
                        label="Datum *"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          newInvoice.status !== status_types.STATUS_DRAFT
                        "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="newInvoice.invoice_date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Abbrechen
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(newInvoice.invoice_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="float-right">
                  <v-text-field
                    v-model="newInvoice.invoice_number"
                    label="Rechnungsnummer"
                    hide-details="auto"
                    width="15"
                    :prefix="getInvoicePrefix()"
                    :disabled="true"
                  ></v-text-field>
                </v-col>
              </v-col>
            </v-row>
          </v-container>

          <v-container
            class="box-border bg-white border border-gray-200 border-solid rounded-b mb-6"
          >
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="newInvoice.notes"
                  append-icon="mdi-note"
                  name="input-7-1"
                  label="Kopf-Text (optional)"
                  row-height="10px"
                  dense
                  auto-grow
                  filled
                  hide-details
                  background-color="#f7f7f7"
                  style="padding: 15px"
                  :disabled="newInvoice.status !== status_types.STATUS_DRAFT"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row>
              <v-col cols="12">
                <!-- Items -->
                <table
                  class="w-full text-center item-table"
                  style="width: 100%"
                >
                  <colgroup>
                    <col style="width: 35%" />
                    <col style="width: 12.5%" />
                    <col style="width: 10%" />
                    <col style="width: 12.5%" />
                    <!--                      <col v-if="discountPerItem === 'YES'" style="width: 15%" />-->
                    <col style="width: 15%" />
                  </colgroup>
                  <thead class="bg-white border border-gray-200 border-solid">
                    <tr>
                      <th
                        class="px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid"
                      >
                        <span>
                          Artikel
                        </span>
                      </th>
                      <th
                        class="px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid"
                      >
                        Anzahl
                      </th>
                      <th
                        class="px-1 py-1 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid"
                      ></th>
                      <th
                        class="px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid"
                      >
                        Stückpreis
                      </th>
                      <th
                        class="px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid"
                      >
                        <span>
                          Summe
                        </span>
                      </th>
                    </tr>
                  </thead>

                  <invoice-item
                    v-for="(item, index) in newInvoice.items"
                    :key="item.id"
                    :index="index"
                    :item-data="item"
                    :invoice-items="newInvoice.items"
                    :currency="currency"
                    :customerSelect="newInvoice.client_id"
                    :status="newInvoice.status"
                    :unit_item="unit_item"
                    @remove="removeItem"
                    @update="updateItem"
                  />
                </table>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                class="flex justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200"
              >
                <div
                  class="d-flex justify-center mb-6"
                  @click="addItem"
                  v-if="newInvoice.status === status_types.STATUS_DRAFT"
                >
                  <div class="ma-2">
                    <v-icon color="primary" left> mdi-plus-circle</v-icon>
                    Position hinzufügen
                  </div>
                </div>
                <v-divider horizontal></v-divider>
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="newInvoice.additional_notes"
                  prepend-icon="mdi-note"
                  name="input-7-1"
                  label="Fuss-Text (optional)"
                  row-height="10px"
                  dense
                  auto-grow
                  filled
                  background-color="#f7f7f7"
                  style="padding: 15px"
                  :disabled="newInvoice.status !== status_types.STATUS_DRAFT"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="ml-4">
                <v-select
                    prepend-icon="mdi-bank-plus"
                    filled
                    background-color="#f7f7f7"
                    style="font-size: 12px;"
                    v-model="newInvoice.fixed_texts_id"
                    :items="this.selectedFixedTexts"
                    name="textblock"
                    item-value="id"
                    item-text="short"
                    label="Auswahl der Zahlungsmodalität"
                    width="15"
                    dense
                    attach=""
                    :disabled="newInvoice.status !== status_types.STATUS_DRAFT"
                >
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ data.item.short + ' - ' + (data.item.description.length > 45 ? data.item.description.slice(0, 45) + '...' : data.item.description) }}
                  </template>
                </v-select>
                <v-text-field
                  prepend-icon="mdi-bank"
                  :value="this.bankDetails[0] ? getBankInformations() : ''"
                  label="Bankdetails"
                  item-value="id"
                  filled
                  background-color="#f7f7f7"
                  style="font-size: 12px;"
                  disabled
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
<!--              <v-col cols="6">
                <v-file-input
                  v-model="newInvoice.files"
                  type="file"
                  chips
                  counter
                  multiple
                  filled
                  background-color="#f7f7f7"
                  label="Pdf upload (optional)"
                  truncate-length="28"
                  style="padding: 15px"
                  accept=".pdf"
                  :disabled="newInvoice.status !== status_types.STATUS_DRAFT"
                ></v-file-input>
              </v-col>-->
              <v-col cols="6" offset="6" class="d-flex flex-column float-right">
                <v-simple-table dense style="width: 100%">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Gesamtsumme Netto</td>
                        <td>
                          <div v-html="formatMoney(newInvoice.sub_total, 0)" />
                        </td>
                      </tr>
                      <tr>
                        <td>Umsatzsteuer 19%</td>
                        <td>
                          <div v-html="formatMoney(newInvoice.tax, 0)" />
                        </td>
                      </tr>
<!--                      <Tax
                        :taxes="taxes"
                        :total="newInvoice.sub_total"
                        :taxTypeFromInvoice="newInvoice.taxtype"
                        :status_types="newInvoice.status"
                        @remove="removeInvoiceTax"
                        @update="updateTax"
                      ></Tax>-->
                      <!--                        <td>{{ newInvoice.tax }}</td>-->
                      <tr class="font-weight-bold">
                        <td>Gesamtsumme</td>
                        <td>
                          <div v-html="formatMoney(newInvoice.total, 0)" />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row></v-row>
          </v-container>
        </v-app>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom_button.theme--light.v-btn.v-btn--disabled {
  background-color: lightgray !important;
}
.custom_button {
  background-color: #181c32d1 !important;
}
</style>

<script>
import StatusTypes from "../../../../stub/statustypes";
import InvoiceStub from "../../../../stub/invoice";
import InvoiceItem from "@/view/pages/dashboard/invoice/InvoiceItem";
import Guid from "guid";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import { LOGOUT } from "@/core/services/store/auth.module";
import moment from "moment";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import CustomerSelect from "@/view/pages/dashboard/invoice/CustomerSelect.vue";
import { formatMoney } from "@/helpers/utilities";

export default {
  name: "Create",
  components: {
    InvoiceItem,
    CustomerSelect,
    Loading
  },
  data() {
    return {
      pageTitle: "",
      menu: false,
      newInvoice: {
        invoice_date: null,
        invoice_number: "",
        user_id: null,
        //invoice_template_id: 1,
        sub_total: 0,
        status: "DRAFT",
        edited: 0,
        total: 0,
        tax: 0,
        notes: null,
        files: null,
        unique_hash: null,
        company_id: 1,
        documents_type_id: null,
        currency_id: 1,
        template_id: null,
        client_id: null,
        permanent_invoice: 0,
        additional_notes: null,
        items: [
          {
            ...InvoiceStub,
            id: Guid.raw()
          }
        ],
        bankDetails_id: 1,
        fixed_texts_id: 1
      },
      company: { Name: "" },
      bankDetails: [],
      currencies: [],
      templates: [],
      status_types: { ...StatusTypes },
      taxes: [],
      selectedCurrency: "", //Todo: need solution
      customerSelect: null,
      selectedFixedTexts: [],
      isLoadingInvoice: false,
      isLoadingData: false,
      isLoading: false,
      InvoiceFields: [
        "customer",
        "customerCustom",
        "company",
        "invoice",
        "invoiceCustom"
      ],
      customerId: null,
      unit_item: []
    };
  },
  created: function() {
    this.newInvoice.invoice_date = moment(new Date(), "YYYY-MM-DD").format(
      "YYYY-MM-DD"
    );

    let unique_hash = this.$route.params.hash;
    let company = this.$route.params.company;
    let documents_type_id = this.$route.query.type;

    if (unique_hash) {
      this.isLoading = true;
      ApiService.query("invoice/show", unique_hash)
        .then(({ data }) => {
          this.isLoading = false;
          this.newInvoice = data.invoice;
          this.customerSelect = data.invoice.client;
          this.company = data.invoice.company;
          this.getDocumentSettings();
        })
        .catch(({ response }) => {
          this.isLoading = false;
          if (response.status === 401) {
            this.$store
              .dispatch(LOGOUT)
              .then(() => this.$router.push({ name: "login" }));
          }
        });
    } else if (!company) {
      // To overview if company not selected
      this.$router.push({
        name: "dashboard",
        pathToRegexpOptions: { strict: true }
      });
    } else {
      this.company = this.$route.params.company;
      this.newInvoice.company_id = this.$route.params.company.CompanyId;
      this.getDocumentSettings();
    }

    if (documents_type_id) {
      this.newInvoice.documents_type_id = documents_type_id;
      this.setPageTitle();
    }
  },
  methods: {
    setPageTitle() {
      let title = "";
      switch (String(this.newInvoice.documents_type_id)) {
        case "1":
          title = "RECHNUNG";
          break;
        case "2":
          title = "GUTSCHRIFT";
          break;
        case "3":
          title = "STORNIERUNG";
          break;
        default:
          title = "";
      }
      this.pageTitle = title;
    },
    getDocumentSettings() {
      let currentYear = new Date().getFullYear();
      ApiService.query(
        "settings",
        currentYear + "/" + this.newInvoice.company_id
      )
        .then(({ data }) => {
          this.isLoading = false;

          this.taxes = data.tax_types;
          this.currencies = data.currencies;
          this.templates = data.templates;

          this.unit_item = data.unit_item;

          if (!this.$route.params.hash) {
            this.newInvoice.invoice_number = data.next_invoice_number;
            this.newInvoice.currency_id = data.currencies[0].id;
            this.newInvoice.template_id = data.templates[0].id;
          }

          this.bankDetails = data.bankDetails;
          this.selectedFixedTexts = data.fixed_texts;
          this.loadingvariable = false;
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            this.loadingvariable = false;
            this.$store
              .dispatch(LOGOUT)
              .then(() => this.$router.push({ name: "login" }));
          }
        });
    },
    onFilePicked() {
      //const files = e.target.files;
      //console.log(this.newInvoice.files);
      /*if (files[0] !== undefined) {
        this.imageName = files[0].name
        if (this.imageName.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.imageUrl = fr.result
          this.imageFile = files[0] // this is an image file that can be sent to server...
        })
      } else {
        this.imageName = ''
        this.imageFile = ''
        this.imageUrl = ''
      }*/
    },
    editDocument() {
      Swal.fire({
        title: "Dieses Dokument wurde bereits finalisiert",
        text:
          "Achtung:\n" +
          "Änderungen an Rechnungsnummern und -daten können die chronologische Reihenfolge der Dokumente beeinträchtigen. Dieses Dokument wurde bereits abgeschlossen.",
        icon: "warning",
        timerProgressBar: true,
        showCancelButton: true,
        cancelButtonText: "Abbrechen",
        confirmButtonText: "Ok"
      }).then(result => {
        if (result.value) {
          this.isLoading = true;
          ApiService.post("/invoice/edit/" + this.newInvoice.unique_hash)
            .then(({ data }) => {
              this.isLoading = false;

              Swal.fire({
                text: data.message,
                icon: "success",
                buttons: false,
                timer: 2000
              }).then(() => {
                window.location.reload();
              });
            })
            .catch(({ response }) => {
              if (response.status === 403) {
                Swal.fire("", response.data.message, "error");
              }
              if (response.status === 401) {
                if (response.status === 401) {
                  this.$store
                    .dispatch(LOGOUT)
                    .then(() => this.$router.push({ name: "login" }));
                }
              }
              this.isLoading = false;
            });
        }
      });
    },
    saveInvoice(status) {
      if (this.isValid()) {
        this.isLoading = true;
        this.newInvoice.status = status;

        let formData = new FormData();

        // files
        if (this.newInvoice.files) {
          for (let file of this.newInvoice.files) {
            if (file.size) formData.append("file[]", file, file.name);
          }
        }
        formData.append("json", JSON.stringify(this.newInvoice));
        //formData.forEach(el => console.log(el))

        //ApiService.post("/invoice/store", { newInvoice: this.newInvoice, files: logoData })
        ApiService.post("/invoice/store", formData)
          .then(({ data }) => {
            this.isLoading = false;
            this.newInvoice.status = data.invoice.status;
            this.newInvoice.unique_hash = data.invoice.unique_hash;
            this.newInvoice.user_id = data.invoice.user_id;

            // check if InvoiceNumber updated
            if (
              this.newInvoice.invoice_number !== data.invoice.invoice_number
            ) {
              Swal.fire({
                title: "Aktualisierung erfolgt",
                text:
                  "Die Gutschrift- oder Rechnungsnummer wurde auf die folgende Nummer aktualisiert: " +
                  data.invoice.invoice_number,
                icon: "info",
                heightAuto: false
              });
              this.newInvoice.invoice_number = data.invoice.invoice_number;
            }
          })
          .catch(({ response }) => {
            this.isLoading = false;
            if (response.status === 401) {
              if (response.status === 401) {
                this.$store
                  .dispatch(LOGOUT)
                  .then(() => this.$router.push({ name: "login" }));
              }
            }
          });
      }
    },
    getPdf() {
      this.isLoading = true;
      ApiService.getFile("/invoice/pdf", this.newInvoice.unique_hash)
        .then(response => {
          this.isLoading = false;
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            this.newInvoice.invoice_number + ".pdf"
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(({ response }) => {
          this.isLoading = false;
          if (response.status === 401) {
            if (response.status === 401) {
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          }
        });
    },
    getPDFDraft() {
      this.isLoading = true;
      ApiService.getFile("/invoice/draftPdf", this.newInvoice.unique_hash)
        .then(response => {
          this.isLoading = false;
          const blobUrl = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute("download", this.newInvoice.invoice_number);
          link.click();
          link.remove();
          URL.revokeObjectURL(blobUrl);
        })
        .catch(({ response }) => {
          this.isLoading = false;
          if (response.status === 401) {
            if (response.status === 401) {
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          }
        });
    },
    sendEmail() {
      this.isLoading = true;
      ApiService.post("/invoice/pdf/send/" + this.newInvoice.unique_hash)
        .then(({ data }) => {
          this.isLoading = false;

          Swal.fire({
            title: "E-Mail erfolgreich versendet",
            text: data.message,
            icon: "success",
            heightAuto: false
          });
          //this.newInvoice.status = data.invoice.status;
          //this.newInvoice.unique_hash = data.invoice.unique_hash;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          if (response.status === 401) {
            if (response.status === 401) {
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          } else if (response.status === 404) {
            Swal.fire({
              title: "Warnung " + response.status,
              text: response.data.message,
              icon: "warning",
              heightAuto: false
            });
          }
        });
    },
    isValid() {
      if (!this.newInvoice.client_id) {
        Swal.fire({
          title: "Warnung",
          text: "Bitte wählen Sie ein Unternehmen aus",
          icon: "warning",
          heightAuto: false
        });
      } else {
        return true;
      }
    },
    addItem() {
      this.newInvoice.items.push({
        ...InvoiceStub,
        id: Guid.raw()
      });
    },
    removeItem(index) {
      this.newInvoice.items.splice(index, 1);

      //TODO
      let subTotalTmp = this.newInvoice.items.reduce(function(a, b) {
        return a + b["total"];
      }, 0);
      this.newInvoice.sub_total = subTotalTmp;
      this.newInvoice.tax = Math.round((this.newInvoice.sub_total * 19) / 100);
      this.newInvoice.total = subTotalTmp + this.newInvoice.tax;
    },
    updateItem(data) {
      Object.assign(this.newInvoice.items[data.index], { ...data.item });

      let subTotalTmp = this.newInvoice.items.reduce(function(a, b) {
        return a + b["total"];
      }, 0);
      this.newInvoice.sub_total = subTotalTmp;
      this.newInvoice.total = subTotalTmp + this.newInvoice.tax;
    },
    checkItemsData(index, isValid) {
      this.newInvoice.items[index].valid = isValid;
    },
    formatMoney,
    customerSelected(selectedCustomer) {
      this.newInvoice.client_id = selectedCustomer.id;
    },
    updateTax(data) {
      this.newInvoice.tax = data.item.amount;
      this.newInvoice.total += data.item.amount;
      this.newInvoice.tax_type_id = data.item.id;
    },
    getInvoicePrefix() {
      switch (String(this.newInvoice.documents_type_id)) {
        case "1":
          return "R";
        case "2":
          return "G";
        case "3":
          return "S";
      }
    },
    getBankInformations() {
      if (this.bankDetails.length)
        return (
          this.bankDetails[0].BankName +
          " - " +
          this.bankDetails[0].Iban +
          " - " +
          this.bankDetails[0].BankCode
        );
    }
  },
  computed: {
    //...mapGetters("company", ["defaultCurrency"]),

    //...mapGetters("notes", ["notes"]),

    //...mapGetters("invoice", ["selectedCustomer", "selectedNote"]),

    ...mapGetters("getPageTitle", ["getPageTitle"]),
    total() {
      return this.subtotal + this.totalTax;
    },
    subtotal() {
      return this.newInvoice.items.reduce(function(a, b) {
        return a + b["total"];
      }, 0);
    },
    currency() {
      return this.selectedCurrency;
    }
  },
  watch: {
    newInvoice() {
      this.setPageTitle();
    },
    total() {
      this.newInvoice.tax = Math.round((this.newInvoice.sub_total * 19) / 100);
    }
  }
};
</script>
